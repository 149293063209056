<template>
   <div 
      class="mm-drop-down pa-0"
      style="line-height:0;">
      <v-menu
         v-model="showMenu"
         bottom
         :close-on-click="false"
         :close-on-content-click="false"
      >
         <template v-slot:activator="{ on, attrs }">
            <v-btn dark 
               color="black"
               :block="fullWidth"
               outlined
               v-bind="attrs"
               v-on="on"
               :height="large?38:32"
               :disabled="disabled"
               class="text-none px-2 mx-0"
               style="border-radius:6px;min-width:320px;"
               :style="{
                  'background-color': disabled?'#F6F6F6 !important':'#FFFFFF',
                  'color': disabled?'#AAAAAA !important':''
               }"
               >
               
               <div style="width:calc(100% - 18px);"
                  class="text-left">
                  <span>
                     {{ display_text }}
                  </span>
                  
               </div>

               <div style="width:18px;"
                  class="text-right">
                  <v-icon
                     style="font-size:18px;"
                     class="mr-2"
                     :style="{
                        'color': disabled?'#AAAAAA !important':''
                     }"
                     >
                     mdi-chevron-down
                  </v-icon>
               </div>
            </v-btn>
         </template>
         <v-card class="pa-0">
            <v-card-title class="py-1"
               style="border-bottom: 1px solid #CCCCCC;"
               >
               <v-btn text color="green" class="text-none"
                  @click="closeMenu">
                  Xong
               </v-btn>
               <v-spacer/>

               <v-btn text color="#333333" class="text-none"
                  @click="setAllItemSelected">
                  Select All
               </v-btn>
               <v-btn text color="#333333" class="text-none"
                  @click="selected_items=[]">
                  Select None
               </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">
               <v-list dense>
                  <v-list-item-group
                     v-model="selected_items"
                     multiple
                     >
                     <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        
                     >
                        <template v-slot:default="{ active }">
                           <v-list-item-action>
                              <v-checkbox
                                 :input-value="active"
                                 color="primary"
                              ></v-checkbox>
                           </v-list-item-action>

                           <v-list-item-content>
                              <v-list-item-title>
                                 {{ item.display_name }}
                              </v-list-item-title>
                           </v-list-item-content>
                        </template>
                        
                     </v-list-item>
                  </v-list-item-group>
               </v-list>
            </v-card-text>
         </v-card>
      </v-menu>
   </div>
</template>

<script>
export default {
   props: {
      text: { default: '', type: String },
      disabled: { default: false, type: Boolean },
      large: { default: false, type: Boolean },
      placeholder: { default: 'Chọn nhân viên', type: String },
      mandatory: { default: false, type: Boolean },
      fullWidth: { default: false, type: Boolean }
   },
   computed: {
      display_text: function () {
         if(this.selected_items){
            if(this.selected_items.length == 1 ){
               return this.items[this.selected_items[0]].display_name
            }

            if(this.selected_items.length == this.items.length){
               return "Tất cả nhân viên"
            }

            if(this.selected_items.length >= 1 ){
               return " " + this.selected_items.length + " nhân viên"
            }
         }
         return "Chọn nhân viên"
      }
   },
   data () {
      return {
         showMenu: false,
         items: [
            // {
            //    dispaly_name: '',
            //    item_name: ''
            // }
         ],
         selected_items: null
      }
   },
   methods: {
      closeMenu () {
         this.showMenu = false
         this.emitSelectedItems()
      },
      emitSelectedItems () {
         let selectedItm = this.items.filter ( (item, index) => this.selected_items.includes(index))
         this.$emit('input', selectedItm)
      },
      fetchAllEmployees () {
         let self = this
         this.items = []
         self.axios.get(self.$root.apiLoyverse + "/employees/all").then(res => {
                  if (res.data.status == "OK") {
                     let employees = res.data.content
                     employees.forEach(employee => {
                        if(!employee.is_owner){
                           self.items.push({
                              id: employee.id,
                              display_name: employee.name,
                              // item_name: employee.item_name
                           })
                        }
                     })
                     self.setAllItemSelected()
                     self.emitSelectedItems()
                  }
            }).catch((err) => {
               console.log(err)
            })
      },
      setAllItemSelected () {
         this.selected_items = this.items.map((i, index) => index)
      }
   },
   mounted () {
      this.$nextTick(() => {
         this.fetchAllEmployees()
         // if (this.mandatory) {
         //    if (this.items && this.items.length > 0) {
         //       this.activeItem = this.items[0]
         //    }
         // }
      })
   }
}
</script>

<style>

   .mm-drop-down .v-btn::before {
      background-color: #FFFFFF !important;
   }

   .mm-drop-down .v-btn i:hover {
      background-color: #FFFFFF !important;
   }

   .mm-drop-down .v-btn--outlined {
      border: 1px solid #999999 !important;
   }

   .mm-drop-down .v-btn--outlined:hover {
      border: 2px solid #444444 !important;
   }
   
</style>
