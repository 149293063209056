<template>
  <v-container
    fluid
    class="ma-0 pa-0"
    style="background-color:#f2f2f2 !important;"
  >
    <!-- Filter Bar -->
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="pt-2 pb-1">
        <v-card flat style="background-color:transparent;">
          <v-card-title class="pa-0">
            <v-menu v-model="menuDatePicker" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="py-1 px-4 text-center"
                  style="background-color:white;border-radius:6px;font-size:16px;min-width:180px;"
                  v-on="on"
                >
                  <div style="display:inline-block;" class="mr-4">
                    <v-icon style="font-size:24px;" color="#555555">
                      mdi-calendar-check
                    </v-icon>
                  </div>
                  <div style="display:inline-block;">
                    {{ $moment(range.start).format("DD/MM/YYYY") }}
                    <template
                      v-if="
                        $moment(range.start).format('DDMMYY') !=
                          $moment(range.end).format('DDMMYY')
                      "
                    >
                      - {{ $moment(range.end).format("DD/MM/YYYY") }}
                    </template>
                  </div>
                </div>
              </template>
              <v-card flat>
                <v-card-text class="pa-2">
                  <div>
                    <div style="display:inline-block;vertical-align:top;">
                      <vc-date-picker
                        v-model="range"
                        ref="datepicker"
                        is-range
                        :model-config="modelConfig"
                      />
                    </div>
                    <div v-if="$vuetify.breakpoint.mdAndUp"
                      class="py-0 pl-2 pr-0"
                      style="display:inline-block;vertical-align:top;"
                    >
                      <v-list dense style="min-width:140px;font-size:16px;">
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('today')"
                        >
                          <v-list-item-content>
                            Hôm nay
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('yesterday')"
                        >
                          <v-list-item-content>
                            Hôm qua
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('this-week')"
                        >
                          <v-list-item-content>
                            Tuần này
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('last-week')"
                        >
                          <v-list-item-content>
                            Tuần trước
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('this-month')"
                        >
                          <v-list-item-content>
                            Tháng này
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('last-month')"
                        >
                          <v-list-item-content>
                            Tháng trước
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('last-7-days')"
                        >
                          <v-list-item-content>
                            7 ngày qua
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          style="min-width:100px;"
                          @click="pickdate('last-30-days')"
                        >
                          <v-list-item-content>
                            30 ngày qua
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="black" @click="menuDatePicker = false">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <!-- <v-spacer /> -->

            <div v-if="1==2" class="d-flex ml-2">
              <employees-dropdown large v-model="employeesFilterArr" />
            </div>

            <div class="d-flex"
              :class="{
                  'ml-0 mt-2': $vuetify.breakpoint.smAndDown,
                  'ml-2': $vuetify.breakpoint.mdAndUp,
                }">
              <customers-dropdown
                large
                v-model="customersFilterArr"
              />
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <!-- Average of result Bar -->
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="pt-2 pb-1">
        <v-card flat style="border-radius: 8px;width:100%;">
          <v-card-title v-if="1 == 2" class="py-1"> </v-card-title>

          <v-card-text class="pa-4 pt-8 pb-2">
            <!-- title block -->
            <v-row class="px-4 pb-3">
              <v-col
                cols="3"
                class="px-2 py-2 pt-0"
                text="center"
                align="center"
              >
                <v-row>
                  <v-col class="pa-0 px-1" cols="auto">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      color="grey"
                      style="float:left"
                    >
                      <v-icon dark>
                        mdi-receipt
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-0 px-1" cols="auto">
                    <div class="text-title">
                      Tất cả hóa đơn
                    </div>
                    <div class="text-value">
                      {{ total.totalReciept.toLocaleString() }}
                    </div>
                    <!-- <div class="text-value" style="font-size:16px;">
                      {{ total.totalRecieptMoney.toLocaleString() }}đ
                    </div> -->
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>

              <v-col
                cols="3"
                class="px-2 py-2 pt-0"
                text="center"
                align="center"
              >
                <v-row>
                  <v-col class="pa-0 px-1" cols="auto">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      color="green"
                      style="float:left"
                    >
                      <v-icon dark>
                        mdi-chart-line
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-0 px-1" cols="auto">
                    <div class="text-title">
                      Bán hàng
                    </div>
                    <div class="text-value">
                      {{ total.totalSales.toLocaleString() }}
                    </div>
                    <div class="text-value" style="font-size:16px;">
                      {{ total.totalSalesMoney.toLocaleString() }}đ
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="3"
                class="px-2 py-2 pt-0"
                text="center"
                align="center"
              >
                <v-row>
                  <v-col class="pa-0 px-1" cols="auto">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      color="pink"
                      style="float:left"
                    >
                      <v-icon dark>
                        mdi-chart-arc
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-0 px-1" cols="auto">
                    <div class="text-title">
                      Hoàn tiền
                    </div>
                    <div class="text-value">
                      {{ total.totalRefund.toLocaleString() }}
                    </div>
                    <div class="text-value" style="font-size:16px;">
                      {{ total.totalRefundMoney.toLocaleString() }}đ
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Result Table-->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="pt-2 pb-1">
        <report-table
          :params="params"
          :employeesFilterArr="employeesFilterArr"
          :customersFilterArr="customersFilterArr"
          @report-result="setTotalReport"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmployeesDropdown from "@/components/Loyverse/EmployeesDropdown";
import CustomersDropdown from "@/components/Loyverse/CustomersDropdown";

import ReportTable from "./ReportTable.vue";
export default {
  data() {
    return {
      roles: ['canteen', 'admin'],
      employeesFilterArr: [],
      customersFilterArr: [],
      menuDatePicker: false,
      rangeMode: "day",
      range: {
        start: this.$moment()
          .startOf("day")
          .toDate(),
        end: this.$moment()
          .endOf("day")
          .toDate(),
      },
      modelConfig: {
        start: {
          timeAdjust: "00:00:00",
        },
        end: {
          timeAdjust: "23:59:59",
        },
      },
      total: {
        totalReciept: 0,
        totalRecieptMoney: 0,
        totalSales: 0,
        totalSalesMoney: 0,
        totalRefund: 0,
        totalRefundMoney: 0,
      },
    };
  },
  components: {
    ReportTable,
    EmployeesDropdown,
    CustomersDropdown,
  },
  computed: {
    params() {
      // console.log("index - params");
      return {
        timeRange: this.range,
      };
    },
  },
  methods: {
    pickdate(type) {
      const datepicker = this.$refs.datepicker;

      if (type == "today") {
        this.rangeMode = "day";
        this.range = {
          start: this.$moment()
            .startOf("day")
            .toDate(),
          end: this.$moment()
            .endOf("day")
            .toDate(),
        };
      }
      if (type == "yesterday") {
        this.rangeMode = "day";
        this.range = {
          start: this.$moment()
            .add(-1, "d")
            .startOf("day")
            .toDate(),
          end: this.$moment()
            .add(-1, "d")
            .endOf("day")
            .toDate(),
        };
      }
      if (type == "this-week") {
        this.rangeMode = "week";
        this.range = {
          start: this.$moment()
            .startOf("week")
            .toDate(),
          end: this.$moment()
            .endOf("week")
            .toDate(),
        };
      }

      if (type == "last-week") {
        this.rangeMode = "week";
        this.range = {
          start: this.$moment()
            .startOf("week")
            .add(-7, "d")
            .toDate(),
          end: this.$moment()
            .endOf("week")
            .add(-7, "d")
            .toDate(),
        };
      }

      if (type == "this-month") {
        this.rangeMode = "month";
        this.range = {
          start: this.$moment()
            .startOf("month")
            .toDate(),
          end: this.$moment()
            .endOf("month")
            .toDate(),
        };
      }

      if (type == "last-month") {
        this.rangeMode = "month";
        this.range = {
          start: this.$moment()
            .add(-1, "months")
            .startOf("month")
            .toDate(),
          end: this.$moment()
            .add(-1, "months")
            .endOf("month")
            .toDate(),
        };
      }

      if (type == "last-7-days") {
        this.rangeMode = "7day";
        this.range = {
          start: this.$moment()
            .add(-7, "d")
            .toDate(),
          end: this.$moment().toDate(),
        };
      }

      if (type == "last-30-days") {
        this.rangeMode = "30days";
        this.range = {
          start: this.$moment()
            .add(-30, "d")
            .toDate(),
          end: this.$moment().toDate(),
        };
      }

      this.menuDatePicker = false;
      datepicker.move(this.range.start);

      this.$forceUpdate();
    },
    setTotalReport(data) {
      let self = this;
      self.total.totalReciept = !data.totalReciept ? 0 : data.totalReciept;
      self.total.totalSales = !data.totalSales ? 0 : data.totalSales;
      self.total.totalRefund = !data.totalRefund ? 0 : data.totalRefund;

      self.total.totalRecieptMoney = !data.totalRecieptMoney
        ? 0
        : data.totalRecieptMoney;
      self.total.totalSalesMoney = !data.totalSalesMoney
        ? 0
        : data.totalSalesMoney;
      self.total.totalRefundMoney = !data.totalRefundMoney
        ? 0
        : data.totalRefundMoney;
    },
  }
};
</script>

<style>
.text-title {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.text-value {
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
  color: #222222;
  text-align: left;
}
</style>
