<template>
  <div style="width:100%;height:100%;background-color:#E0E0E0;">
    <v-container fluid>
      <v-row>
        <v-col class="pa-0" cols="12">
          <v-card class="my-0" flat style="border-radius:8px;">
            <v-card-title
              class="py-1"
              style="color:#111111;border-bottom:1px solid #DDDDDD;font-size:22px;"
            >
              <v-spacer />
              Hóa đơn
              <v-spacer />
              <v-btn icon @click="$emit('cancel')">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text v-if="item" class="pt-2 pt-4">
              <table class="detail-report-table">
                <tbody>
                  <tr>
                    <td style="width:35%;">Mã đơn hàng:</td>
                    <td>{{ item.receipt_number }}</td>
                  </tr>

                  <tr>
                    <td style="width:35%;">Thu ngân:</td>
                    <td>{{ item.employee_name }}</td>
                  </tr>

                  <tr>
                    <td style="width:35%;">POS:</td>
                    <td>{{ item.pos_device_name }}</td>
                  </tr>

                  <tr>
                    <td class="pa-0"><v-divider></v-divider></td>
                    <td class="pa-0"><v-divider></v-divider></td>
                  </tr>
                  <tr>
                    <td style="width:35%;">Mặt hàng:</td>
                    <td>
                      <v-list class="pa-0">
                        <v-list-item
                          class="pa-0"
                          v-for="(lineItem, indexLnItem) in item.line_items"
                          :key="indexLnItem"
                        >
                          <v-list-item-content class="py-0">
                            <v-list-item-title>
                              {{ lineItem.item_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                lineItem.quantity +
                                  " x " +
                                  lineItem.price.toLocaleString()
                              }}đ
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item></v-list
                      >
                    </td>
                  </tr>
                  <template v-if="item.total_discounts.length > 0">
                    <tr>
                      <td class="pa-0"><v-divider></v-divider></td>
                      <td class="pa-0"><v-divider></v-divider></td>
                    </tr>
                    <tr>
                      <td style="width:35%;">Giảm giá:</td>
                      <td>
                        <v-list class="pa-0">
                          <v-list-item
                            class="pa-0"
                            v-for="(lineItem,
                            idxTotalDis) in item.total_discounts"
                            :key="idxTotalDis"
                          >
                            <v-list-item-content class="py-0">
                              <v-list-item-title>
                                {{ lineItem.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ '- ' + lineItem.money_amount.toLocaleString() }}đ
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item></v-list
                        >
                      </td>
                    </tr>
                  </template>

                  <tr>
                    <td class="pa-0"><v-divider></v-divider></td>
                    <td class="pa-0"><v-divider></v-divider></td>
                  </tr>
                  <tr>
                    <td style="width:35%;font-weight:600;">Tổng:</td>
                    <td>{{ item.total_money.toLocaleString() }}đ</td>
                  </tr>
                  <tr>
                    <td style="width:35%;">{{ item.payments[0].name }}:</td>
                    <td>
                      {{ item.payments[0].money_amount.toLocaleString() }}đ
                    </td>
                  </tr>
                  <tr>
                    <td class="pa-0"><v-divider></v-divider></td>
                    <td class="pa-0"><v-divider></v-divider></td>
                  </tr>
                  <tr>
                    <td style="width:35%;font-size:14px;">
                      {{ item.receipt_dateTxt }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {},
  mounted() {
    console.log("detail item:", this.item);
  },
};
</script>

<style>
/** Desktop */
@media only screen and (min-width: 960px) {
  .detail-report-table {
    width: 100%;
  }
  .detail-report-table td {
    padding: 6px;
    font-size: 16px;
    vertical-align: top;
  }
}
/** Mobile */
@media only screen and (max-width: 959px) {
  .detail-report-table {
    width: 100%;
  }
  .detail-report-table td {
    padding: 6px;
    font-size: 16px;
  }
}
</style>
